"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectKeys = void 0;
/**
 *
 * @param object
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function objectKeys(object) {
  return Object.keys(object);
}
exports.objectKeys = objectKeys;

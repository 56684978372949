import { InjectionToken } from '@angular/core';
import { asArray } from '@ng-doc/core';
const NG_DOC_API_LIST_TOKEN = new InjectionToken('API_LIST_TOKEN');
const NG_DOC_CONTEXT = new InjectionToken('NG_DOC_CONTEXT');
const NG_DOC_PAGE_PROCESSOR = new InjectionToken('NG_DOC_PAGE_PROCESSOR');
const NG_DOC_PAGE_CUSTOM_PROCESSOR = new InjectionToken('NG_DOC_PAGE_CUSTOM_PROCESSOR');
/**
 * Provide a main processor to replace html nodes with an Angular component.
 * Main processors are run before custom processors.
 * @param processors - The processor to provide.
 */
function provideMainPageProcessor(processors) {
  return asArray(processors).map(processor => ({
    provide: NG_DOC_PAGE_PROCESSOR,
    useValue: processor,
    multi: true
  }));
}
/**
 * Provide a processor to replace html nodes with an Angular component.
 * @param processors - The processor to provide.
 */
function providePageProcessor(processors) {
  return asArray(processors).map(processor => ({
    provide: NG_DOC_PAGE_CUSTOM_PROCESSOR,
    useValue: processor,
    multi: true
  }));
}
const NG_DOC_PAGE_SKELETON = new InjectionToken('NG_DOC_PAGE_SKELETON');

/**
 * Token that can be used to provide custom theme.
 * You must compile your theme to CSS and add it to `assets` so that NgDoc can load it dynamically.
 *
 * You always should provide custom themes in root of your application with `multi: true` parameter.
 *
 * For example
 * ```typescript
 * @NgModule({
 *    declarations: [AppComponent],
 *    imports: [],
 *    providers: [
 *        {provide: NG_DOC_THEME, useValue: {id: 'CustomTheme', path: '/assets/themes/custom.css'}, multi: true}
 *    ],
 *    bootstrap: [AppComponent],
 * })
 * export class AppModule {}
 * ```
 */
const NG_DOC_THEME = new InjectionToken(`NG_DOC_THEME`);
/**
 * Default theme for application if user didn't change it manually.
 *
 * You can provide this token in the root of you application, to set theme by default.
 */
const NG_DOC_DEFAULT_THEME_ID = new InjectionToken(`NG_DOC_DEFAULT_THEME`);
const NG_DOC_TYPE_CONTROL = new InjectionToken('NG_DOC_TYPE_CONTROL');

/**
 * Generated bundle index. Do not edit.
 */

export { NG_DOC_API_LIST_TOKEN, NG_DOC_CONTEXT, NG_DOC_DEFAULT_THEME_ID, NG_DOC_PAGE_CUSTOM_PROCESSOR, NG_DOC_PAGE_PROCESSOR, NG_DOC_PAGE_SKELETON, NG_DOC_THEME, NG_DOC_TYPE_CONTROL, provideMainPageProcessor, providePageProcessor };

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NgDocHtmlParser = void 0;
const CSSWhat = require("css-what");
const css_what_1 = require("css-what");
const posthtml_parser_1 = require("posthtml-parser");
const posthtml_render_1 = require("posthtml-render");
const helpers_1 = require("../helpers");
class NgDocHtmlParser {
  constructor(html) {
    this.html = html;
    this.parsedHTML = (0, posthtml_parser_1.parser)(this.html);
  }
  find(selector) {
    const selectors = CSSWhat.parse(selector)[0];
    const tagSelectors = selectors.filter(selector => selector.type === css_what_1.SelectorType.Tag);
    const attrSelectors = selectors.filter(selector => selector.type === css_what_1.SelectorType.Attribute);
    const nodes = [...this.parsedHTML];
    while (nodes.length) {
      const shiftNode = nodes.shift();
      const node = !(0, helpers_1.isPresent)(shiftNode) || !(0, helpers_1.isNodeTag)(shiftNode) ? undefined : shiftNode;
      const tagSelectorMatch = tagSelectors.every(tagSelector => tagSelector.name.toLowerCase() === String(node?.tag).toLowerCase()) || !tagSelectors.length;
      const attrSelectorsMatch = attrSelectors.every(attrSelector => {
        const nodeAttrs = node?.attrs ?? {};
        const hasAttr = Object.keys(nodeAttrs).includes(attrSelector.name) || Object.keys(nodeAttrs).includes(`[${attrSelector.name}]`);
        const attrValue = nodeAttrs[attrSelector.name] ?? nodeAttrs[`[${attrSelector.name}]`];
        return hasAttr && !attrSelector.value || attrValue === attrSelector.value;
      }) || !attrSelectors.length;
      if (tagSelectorMatch && attrSelectorsMatch) {
        return node;
      } else {
        nodes.push(...(0, helpers_1.asArray)(node?.content).flat());
      }
    }
    return undefined;
  }
  removeAttribute(node, name) {
    if ((0, helpers_1.isNodeTag)(node) && Object.keys(node?.attrs ?? {}).includes(name) && node.attrs) {
      delete node.attrs[name];
    }
    if ((0, helpers_1.isNodeTag)(node) && Object.keys(node?.attrs ?? {}).includes(`[${name}]`) && node.attrs) {
      delete node.attrs[`[${name}]`];
    }
  }
  setAttribute(node, name, value) {
    if ((0, helpers_1.isNodeTag)(node)) {
      if (!node.attrs) {
        node.attrs = {};
      }
      node.attrs[name] = value ?? '';
    }
  }
  setAttributesFromSelectors(element, selectors) {
    const elementAttrs = element.attrs ?? {};
    selectors.forEach(selector => {
      if (selector.type === 'attribute' && !elementAttrs[selector.name] && !elementAttrs[`[${selector.name}]`]) {
        this.setAttribute(element, selector.name, selector.value);
      }
    });
  }
  fillAngularAttributes(element, properties) {
    (0, helpers_1.objectKeys)(properties).forEach(key => {
      this.removeAttribute(element, String(key));
      this.setAttribute(element, `[${String(key)}]`, properties[key]);
    });
  }
  serialize() {
    return (0, posthtml_render_1.render)(this.parsedHTML);
  }
}
exports.NgDocHtmlParser = NgDocHtmlParser;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stringify = void 0;
/**
 * Stringify data to JSON. If data is undefined, return string 'undefined'.
 * @param data - Data to stringify.
 */
function stringify(data) {
  return data === undefined ? 'undefined' : JSON.stringify(data, null, 2);
}
exports.stringify = stringify;

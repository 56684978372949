/* eslint-disable */
/**
 * Implements lazy initialization for getter or memoization of a function call similar to pure {@link: Pipe}.
 * Replaces getter with its calculated value upon first call or keeps track of last call arguments and returned
 * value for function, skipping calculation when arguments are strictly the same.
 *
 * @throws error if used not on getter or function
 *
 * CAUTION: `this` is not available inside such functions/getters, they must be pure.
 */
function ngDocMakePure(_target, propertyKey, {
  get,
  enumerable,
  value
}) {
  if (get) {
    return {
      enumerable,
      get() {
        const value = get.call(this);
        Object.defineProperty(this, propertyKey, {
          enumerable,
          value
        });
        return value;
      }
    };
  }
  if (typeof value !== 'function') {
    throw new Error('ngDocMakePure can only be used with functions or getters');
  }
  const original = value;
  return {
    enumerable,
    get() {
      let previousArgs = [];
      let previousResult;
      const patched = (...args) => {
        if (previousArgs.length === args.length && args.every((arg, index) => arg === previousArgs[index])) {
          return previousResult;
        }
        previousArgs = args;
        previousResult = original.apply(this, args);
        return previousResult;
      };
      Object.defineProperty(this, propertyKey, {
        value: patched
      });
      return patched;
    }
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ngDocMakePure };

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isIterable = void 0;
/**
 *
 * @param value
 */
function isIterable(value) {
  return value !== null && typeof value[Symbol.iterator] === 'function' && typeof value !== 'string';
}
exports.isIterable = isIterable;

import { Observable, of, pipe } from 'rxjs';
import { startWith, switchMap, map, catchError, tap } from 'rxjs/operators';

/**
 * @param subscribable
 */
function fromSubscribe(subscribable) {
  return new Observable(observer => {
    const subscription = subscribable.subscribe(next => observer.next(next), error => observer.error(error), () => observer.complete());
    return () => subscription.unsubscribe();
  });
}

/**
 *
 * @param retry
 */
function observableState(retry) {
  return source => {
    let state = {
      result: null,
      error: null,
      pending: false
    };
    return (retry ? retry.pipe(startWith(null)) : of(null)).pipe(switchMap(() => source.pipe(
    // Map result of observable
    map(result => ({
      result,
      pending: false
    })),
    // Map error of observable
    catchError(error => of({
      result: null,
      error,
      pending: false
    })),
    // Start from pending state and clear error
    startWith({
      error: null,
      pending: true
    }),
    // Merge the current state with new state
    tap(updatedState => state = {
      ...state,
      ...updatedState
    }), map(() => state))));
  };
}
class NgDocZoneDetachSourceOperator {
  constructor(ngZone) {
    this.ngZone = ngZone;
  }
  call(observer, source) {
    return this.ngZone.runOutsideAngular(() => source.subscribe(observer));
  }
}
/**
 *
 * @param ngZone
 */
function zoneDetachSource(ngZone) {
  return source => source.lift(new NgDocZoneDetachSourceOperator(ngZone));
}
/**
 *
 * @param ngZone
 */
function zoneDetach(ngZone) {
  return source => new Observable(subscriber => source.subscribe({
    next: value => ngZone.runOutsideAngular(() => subscriber.next(value)),
    error: error => ngZone.runOutsideAngular(() => subscriber.error(error)),
    complete: () => ngZone.runOutsideAngular(() => subscriber.complete())
  }));
}
/**
 *
 * @param ngZone
 */
function ngDocZoneAttach(ngZone) {
  return source => new Observable(subscriber => source.subscribe({
    next: value => ngZone.run(() => subscriber.next(value)),
    error: error => ngZone.run(() => subscriber.error(error)),
    complete: () => ngZone.run(() => subscriber.complete())
  }));
}
/**
 *
 * @param ngZone
 */
function ngDocZoneDetach(ngZone) {
  return pipe(zoneDetach(ngZone), zoneDetachSource(ngZone));
}
/**
 *
 * @param ngZone
 */
function ngDocZoneOptimize(ngZone) {
  return pipe(zoneDetach(ngZone), zoneDetachSource(ngZone), ngDocZoneAttach(ngZone));
}

/**
 * Generated bundle index. Do not edit.
 */

export { fromSubscribe, ngDocZoneAttach, ngDocZoneDetach, ngDocZoneOptimize, observableState };

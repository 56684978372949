"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NG_DOC_ELEMENT = void 0;
/**
 * Class name that uses to mark tags that are generated by ng-doc and should be styled
 */
exports.NG_DOC_ELEMENT = 'ngde';

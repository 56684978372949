"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EMPTY_FUNCTION = exports.EMPTY_MAP = exports.EMPTY_ARRAY = void 0;
exports.EMPTY_ARRAY = [];
exports.EMPTY_MAP = new Map();
// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_FUNCTION = () => {};
exports.EMPTY_FUNCTION = EMPTY_FUNCTION;

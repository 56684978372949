const FOCUS_EVENT = 'focusin';
const BLUR_EVENT = 'focusout';
const NG_DOC_ALWAYS_FALSE_HANDLER = () => false;
const NG_DOC_DEFAULT_HANDLER = item => item;
const NG_DOC_DEFAULT_STRINGIFY = item => String(item);

/**
 * Generated bundle index. Do not edit.
 */

export { BLUR_EVENT, FOCUS_EVENT, NG_DOC_ALWAYS_FALSE_HANDLER, NG_DOC_DEFAULT_HANDLER, NG_DOC_DEFAULT_STRINGIFY };

import { isPresent } from '@ng-doc/core/helpers/is-present';
import { ElementRef } from '@angular/core';

/**
 * @param defaultConfig
 * @param currentConfig
 * @param hostConfig
 */
/**
 *
 * @param defaultConfig
 * @param currentConfig
 * @param hostConfig
 */
function mergeOverlayConfigs(defaultConfig, currentConfig, hostConfig = {}) {
  const configKeys = Object.keys({
    ...defaultConfig,
    ...currentConfig
  });
  const newConfig = {};
  for (const key of configKeys) {
    newConfig[key] = defaultConfig[key] !== currentConfig[key] && isPresent(currentConfig[key]) ? currentConfig[key] : (hostConfig && hostConfig[key]) ?? defaultConfig[key];
  }
  return newConfig;
}

/**
 * @param element
 */
function toElement(element) {
  return element instanceof ElementRef ? element.nativeElement : element;
}

/**
 * Generated bundle index. Do not edit.
 */

export { mergeOverlayConfigs, toElement };

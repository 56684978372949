import { __decorate, __metadata } from 'tslib';
import * as i0 from '@angular/core';
import { EventEmitter, ElementRef, NgZone, ChangeDetectorRef, Directive, Output, HostBinding } from '@angular/core';
import { FOCUS_EVENT, BLUR_EVENT } from '@ng-doc/ui-kit/constants';
import { toElement } from '@ng-doc/ui-kit/helpers';
import { ngDocZoneOptimize } from '@ng-doc/ui-kit/observables';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
var NgDocFocusCatcherDirective_1;
let NgDocFocusCatcherDirective = class NgDocFocusCatcherDirective {
  static {
    NgDocFocusCatcherDirective_1 = this;
  }
  constructor(elementRef, ngZone, changeDetectorRef) {
    this.elementRef = elementRef;
    this.ngZone = ngZone;
    this.changeDetectorRef = changeDetectorRef;
    this.focusEvent = new EventEmitter();
    this.blurEvent = new EventEmitter();
    this.focused = false;
    NgDocFocusCatcherDirective_1.observeFocus(toElement(this.elementRef)).pipe(ngDocZoneOptimize(this.ngZone), untilDestroyed(this)).subscribe(event => {
      this.focused = event.type === FOCUS_EVENT;
      this.focused ? this.focusEvent.emit(event) : this.blurEvent.emit(event);
      this.changeDetectorRef.markForCheck();
    });
  }
  static observeFocus(element) {
    return merge(fromEvent(element, FOCUS_EVENT), fromEvent(element, BLUR_EVENT)).pipe(debounceTime(0), distinctUntilChanged((a, b) => a.type === b.type));
  }
  static {
    this.ɵfac = function NgDocFocusCatcherDirective_Factory(t) {
      return new (t || NgDocFocusCatcherDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgDocFocusCatcherDirective,
      selectors: [["", "ngDocFocusCatcher", ""]],
      hostVars: 1,
      hostBindings: function NgDocFocusCatcherDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-ng-doc-focused", ctx.focused);
        }
      },
      outputs: {
        focusEvent: "focusEvent",
        blurEvent: "blurEvent"
      },
      exportAs: ["ngDocFocusCatcher"],
      standalone: true
    });
  }
};
NgDocFocusCatcherDirective = NgDocFocusCatcherDirective_1 = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, NgZone, ChangeDetectorRef])], NgDocFocusCatcherDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocFocusCatcherDirective };

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.kebabCase = void 0;
/**
 *
 * @param str
 */
function kebabCase(str) {
  return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)?.map(part => part.toLowerCase())?.join('-') ?? '';
}
exports.kebabCase = kebabCase;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSameObject = void 0;
/**
 *
 * @param a
 * @param b
 */
function isSameObject(a, b) {
  return Object.keys(a).every(key => {
    if (typeof a[key] === 'object' && typeof b[key] === 'object') {
      return isSameObject(a[key] ?? {}, b[key] ?? {});
    }
    return a[key] === b[key];
  });
}
exports.isSameObject = isSameObject;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractValueOrThrow = exports.extractValue = void 0;
/**
 *
 * @param value
 */
function extractValue(value) {
  try {
    return extractValueOrThrow(value);
  } catch {
    return '';
  }
}
exports.extractValue = extractValue;
/**
 *
 * @param value
 */
function extractValueOrThrow(value) {
  return new Function(`return ${value}`)();
}
exports.extractValueOrThrow = extractValueOrThrow;

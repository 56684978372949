"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildPlaygroundDemoPipeTemplate = exports.buildPlaygroundDemoTemplate = void 0;
const CSSWhat = require("css-what");
const css_what_1 = require("css-what");
const classes_1 = require("../classes");
const constants_1 = require("../constants");
const object_keys_1 = require("./object-keys");
/**
 *
 * @param template
 * @param selector
 * @param content
 * @param inputs
 * @param preview
 */
function buildPlaygroundDemoTemplate(template, selector, content, inputs, preview = true) {
  const parser = new classes_1.NgDocHtmlParser(template);
  const selectors = CSSWhat.parse(selector)[0];
  if (selectors) {
    const rootElement = parser.find(constants_1.NG_DOC_DYNAMIC_SELECTOR) ?? parser.find(selector);
    if (rootElement) {
      parser.setAttributesFromSelectors(rootElement, selectors);
      if (String(rootElement.tag).toLowerCase() === constants_1.NG_DOC_DYNAMIC_SELECTOR.toLowerCase()) {
        rootElement.tag = selectors.find(selector => selector.type === css_what_1.SelectorType.Tag)?.name ?? 'div';
      }
      inputs && parser.fillAngularAttributes(rootElement, inputs);
    }
  }
  return replaceContent(parser.serialize(), content ?? {}, preview).replace(/=""/g, '')
  // Removes empty lines
  .replace(/^\s*\n/gm, '');
}
exports.buildPlaygroundDemoTemplate = buildPlaygroundDemoTemplate;
/**
 *
 * @param template
 * @param name
 * @param content
 * @param inputs
 * @param preview
 */
function buildPlaygroundDemoPipeTemplate(template, name, content, inputs, preview = true) {
  const preparedTemplate = buildPlaygroundDemoTemplate(template, '', content, inputs, preview);
  const listOfParameters = (0, object_keys_1.objectKeys)(inputs ?? {}).map(key => `:${inputs?.[key]}`).join('').trim();
  return preparedTemplate.replace(new RegExp(`\\| ${name}`, 'gm'), `| ${name}${listOfParameters}`);
}
exports.buildPlaygroundDemoPipeTemplate = buildPlaygroundDemoPipeTemplate;
/**
 *
 * @param htmlData
 * @param content
 * @param preview
 */
function replaceContent(htmlData, content, preview) {
  (0, object_keys_1.objectKeys)(content).forEach(key => {
    const condition = preview ? content[key] : `
				<ng-container *ngIf="content['${key}']">
					${content[key]}
				</ng-container>`.trim();
    htmlData = htmlData.replace(new RegExp(`{{\\s*content.${key}\\s*}}`, 'gm'), condition ? `\n${condition}\n` : '');
  });
  return htmlData;
}

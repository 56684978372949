"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractFunctionDefaults = void 0;
const extract_value_1 = require("./extract-value");
/**
 * Extracts the default values of a function parameters.
 * It returns an array of the default values because after minification the parameter names are lost.
 * @param fn - Function to extract the default values from
 */
function extractFunctionDefaults(fn) {
  const name = fn.name || 'function';
  return fn.toString()
  // Get the parameters declaration between the parenthesis
  .match(new RegExp(`^${name}\\s*[^\\(]*\\(\\s*([^\\)]*)\\)`, 'm'))?.[1]
  // Get rid of comments
  .replace(/(\/\*[\s\S]*?\*\/)/gm, '').split(',')
  // Convert it into an object
  .map(param => {
    // Split parameter name from value
    const paramMatch = param.match(/([_$a-zA-Z][^=]*)(?:=([^=]+))?/);
    if (paramMatch) {
      // Eval each default value, to get strings, variable refs, etc.
      return (0, extract_value_1.extractValue)(paramMatch[2]);
    }
    return undefined;
  }) ?? [];
}
exports.extractFunctionDefaults = extractFunctionDefaults;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asArray = void 0;
const is_iterable_1 = require("./is-iterable");
const is_present_1 = require("./is-present");
/**
 *
 * @param data
 * @param {...any} items
 */
function asArray(...items) {
  return items.map(data => {
    if (!(0, is_present_1.isPresent)(data)) {
      return [];
    }
    return Array.isArray(data) ? data : (0, is_iterable_1.isIterable)(data) ? Array.from(data) : [data];
  }).flat();
}
exports.asArray = asArray;

import { InjectionToken } from '@angular/core';
const NG_DOC_ASSETS_PATH = new InjectionToken('NG_DOC_ASSETS_PATH');
const NG_DOC_COMPONENT_CONTEXT = new InjectionToken('NG_DOC_COMPONENT_CONTEXT');
const NG_DOC_CUSTOM_ICONS_PATH = new InjectionToken('NG_DOC_CUSTOM_ICONS_PATH');

/**
 * Generated bundle index. Do not edit.
 */

export { NG_DOC_ASSETS_PATH, NG_DOC_COMPONENT_CONTEXT, NG_DOC_CUSTOM_ICONS_PATH };
